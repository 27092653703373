import { type Environment } from './environment.interface';

export const environment: Environment = {
  production: true,
  oauth_discovery_uri:
    'https://barcociamdevstable.b2clogin.com/barcociamdevstable.onmicrosoft.com/v2.0/.well-known/openid-configuration?p=B2C_1A_Signin',
  oauth_scope: 'openid offline_access https://barcociamdevstable.onmicrosoft.com/digitalpath/api',
  oauth_client_id: 'c3003dcb-e6e1-41bb-87db-b4aaae291b7b',
  public_ecr_code: '<code>',
};
